import { useMoralis } from "react-moralis";

import 'antd/dist/antd.css';
import { notification } from 'antd';

const useWeb3Utils = () => {

    const { Moralis } = useMoralis();
    // console.log(" loading useWeb3Utils")

    const openNotification = (message) => {
      notification.error({
        message: 'TransactionError',
        description:
          message,
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
    };

    const executeWeb3CallAndConfirm = async(options) => {
      try{
        const transactionResult =  await Moralis.Web3.executeFunction(options)
    
          console.log("waiting for 5 confirmations")
          await transactionResult.wait(5)
          console.log("maybe works?")

      } catch (error) {
        openNotification(error.data?.message || error.message)
        console.warn(`there was an error while executeFunction ${JSON.stringify(error)}`)
      }
        
          // execute()
    
      }

      return {executeWeb3CallAndConfirm}

}

  export {useWeb3Utils}
