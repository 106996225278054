
import { useMoralis } from "react-moralis";
import { useWeb3ExecuteFunction } from "react-moralis";
import { ERC721 as ERC721Abi } from "../contracts/index"
import { useState, useEffect } from "react";

 const useGetMintPriceForNFT =  (NFTAddress) => {

    const [mintPrice, setMintPrice] = useState()

    const { isWeb3Enabled } = useMoralis()
    const {fetch: fetchWeb3} = useWeb3ExecuteFunction();

    useEffect(() => {

      const fetchData = async () => {
        console.log("isWeb3Enabled " + isWeb3Enabled)
        if(!mintPrice && isWeb3Enabled){



          const ops = {
            contractAddress: NFTAddress,
            functionName: "getNFTMintPrice",
            abi: ERC721Abi.abi,
            params: {
            },
          };
      
      
          await fetchWeb3({
            params: ops,
            onSuccess: (mintPrice) => {
              console.log("getNFTMintPrice success " + mintPrice)
              console.log("get price token sucess " + mintPrice);
      
              setMintPrice(Number(mintPrice))
              
              console.log("NFTAddress "+ NFTAddress)
              console.log("mintPrice " + mintPrice)
      
            },
            onError: (error) => {
              console.error("getNFTMintPrice  error" + error + " isWeb3Enabled  " + isWeb3Enabled)
            },
          });
    
        }
      }

      fetchData()
     
      

      },[isWeb3Enabled, NFTAddress, fetchWeb3, mintPrice])


      return {mintPrice}
      
    
  
    // we need this disabled do not remove at least fornow
  }
  export {useGetMintPriceForNFT}