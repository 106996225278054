import { GraphQLClient, gql } from 'graphql-request'
import { InGameAsset } from "../types"
const CLIENT_SECRET =
  process.env.REACT_APP_FAUNA_ADMIN_KEY || process.env.FAUNA_CLIENT_SECRET
const FAUNA_GRAPHQL_BASE_URL = 'https://graphql.us.fauna.com/graphql'

const graphQLClient = new GraphQLClient(FAUNA_GRAPHQL_BASE_URL, {
  headers: {
    authorization: `Bearer ${CLIENT_SECRET}`,
  },
})



export const findNFTCollectionById = (id: string) => {
  const query = gql`
  query FindNFTCollectionByID($id: ID!) {
    findNFTCollectionByID(id: $id) {
      _id
      collectionImage
      name
      description
      contractAddress
      mintDecimals
      mintTokenName
      banner
    }
  }
  `
  return graphQLClient
    .request(query, {id})
    .then( data => data.findNFTCollectionByID)
}



export const findNFTCollectionByAddress = (id: string) => {
  const query = gql`
  query FindNFTCollectionByAddress($contractAddress: String!) {
    findNFTCollectionByAddress(contractAddress: $contractAddress) {
      _id
      collectionImage
      name
      description
      contractAddress
      mintDecimals
      mintTokenName
      banner
    }
  }
  `
  return graphQLClient
    .request(query, {id})
    .then( data => data.findNFTCollectionByAddress)
}



export const findAllMarketPlaceItems = () => {
  const query = gql`
  query FindAllMarketPlaceItems {
    allMarketPlaceItems {
      data {
        _id,
        NFTAddress
        ERC20Address
        contractAddress
        mintDecimals
        mintTokenName
  
      }
    }
  }
  `
  return graphQLClient
    .request(query)
    .then(({ allMarketPlaceItems: { data } }) => data)
}


export const findInGameAssetById = (id: String) => {
  const query = gql`
  query FindInGameAssetById($id: ID!) {
    findInGameAssetByID(id: $id) {
      description
    }

  }
  `
  return graphQLClient
    .request(query, {id})
    .then(({ findInGameAssetById }): InGameAsset => {
      return findInGameAssetById;
    }
     )
}
export const findMarketplaceById = (id: string) => {
  const query = gql`
  query FindMarketplaceByID($id: ID!) {
    findMarketplaceByID(id: $id) {
      _id
      customStyleSheetURL
      blockchain
      blockchainId
      marketplaceNFTCollections {   
        
        data{
          _id
          NFTCollection{
        _id
        contractAddress
        publicMint
        collectionImage
        banner
        name
        mintDecimals
        mintTokenName
        description
        baseTokenURI
      }}
      
        
      }
    }
  }
  `
  return graphQLClient
    .request(query, {id})
    .then(data => {
      // console.log("find market data " + JSON.stringify(data.findMarketplaceByID))
      return data.findMarketplaceByID;
    })
}

