import { MintButton } from "./MintButton"
import {
  useParams
} from "react-router-dom";

import { findNFTCollectionById } from "../lib/fauna"
import { useEffect, useState } from "react";
import htmr from 'htmr';

function NFTDetails({ inputValue, setInputValue }) {
  const { nftCollectionId } = useParams();
  const [loading, setLoading] = useState(true)
  const [NFTCollection, setNFTCollection] = useState(inputValue)

  useEffect(() => {


    const loadNFTFromURL = async () => {
      if (NFTCollection) {
        console.log("NFTCollection " + NFTCollection)
        setLoading(false)
        return
      }

      console.log("nftCollectionId " + nftCollectionId)
      const res = await findNFTCollectionById(nftCollectionId)
      console.log("res findNFTCollectionById " + JSON.stringify(res))
      console.log("res.contractAddres " + res.contractAddres)
      if (res.contractAddress) {
        setNFTCollection(res)
      }
      setLoading(false)

    }
    loadNFTFromURL()


  }, [NFTCollection, nftCollectionId])






  console.log("inputValue NFTDetails " + JSON.stringify(inputValue))

  if (loading || !NFTCollection) {
    return "loading"
  }

  const renderDescription = () => {
    const richDescription = htmr(NFTCollection.description.replaceAll(", \"",",'").replaceAll("\",","',"))
    return (
      <div>
        {richDescription}
      </div>
    )
  }
  const renderMintButton = () => {
    // return<button>mint</button>
    return <MintButton inputValue={NFTCollection}></MintButton>
  }

  return (
    <>
      <div className="">


        <div className="bg-cover bg-center  h-auto text-white py-24 px-10 object-fill" 
      style={{"backgroundImage": `url(${NFTCollection.banner || ""})`}}>
       <div className="md:w-1/2">
        {/* <p className="font-bold text-sm uppercase">Services</p>
        <p className="text-3xl font-bold">Multimedia products</p>
        <p className="text-2xl mb-10 leading-none">Atractive designs for your brand</p>
        <p className="bg-purple-800 py-4 px-8 text-white font-bold uppercase text-xs rounded hover:bg-gray-200 hover:text-gray-800">Contact us</p> */}
        </div>  
    </div>
    </div>
    <div className="mt-14 lg:mx-28">
{/* 
<div className="col-span-1 lg:order-1 order-2" >
        <img
     alt={`${NFTCollection.name} banner`}
     src="https://nebula-nft-token-assets-storage.s3.amazonaws.com/NFT-Collection-Banners/Asteroid+Banner.jpg"
     >
     </img>
          </div> */}


        <div className=" grid grid-cols-1 md:grid-cols-2 px-4">
     
        
          <div className="col-span-1 lg:order-1 order-2" >
         
          <div >
            <p className="text-3xl font-extrabold mb-8">
              {NFTCollection.name}

            </p>
          </div>
            {renderMintButton()}
            <div>
              <div className="mb-8  text-xl font-bold">
                Description
              </div>
              <div className="mb-10">
                {renderDescription()}
              </div>
            </div>
            {/* <div>
              <div className="my-8 text-xl font-bold">
                Properties
              </div>
              <div>
                <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-6 md:grid-cols-2 xl:grid-cols-3">
                  <div class="bg-blue-100	 p-2 space-y-3 border-2 border-blue-400 dark:border-blue-300 rounded-xl">
                    <span class="text-md font-semibold text-gray-700 capitalize dark:text-white">
                      Reduces Options by 50%
                    </span>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <div className="-mt-10 col-span-1	w-96 order-1">
            <img
              alt="Collection"
              className="rounded-lg w-screen md:w-96"
              src={NFTCollection.collectionImage}
            >
            </img>
          </div>
        </div>


      </div>
    </>
  );
}

export default NFTDetails;
