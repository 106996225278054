import { ERC721 as ERC721Abi, ERC20 as ERC20Schema } from "../contracts/index"
import { useWeb3ExecuteFunction } from "react-moralis";
import { useState, useContext, useEffect } from "react";
import { useMoralis } from "react-moralis";
import { useChain } from "react-moralis";
import { MarketplaceContext } from "./marketplaceContext"
import { useWeb3Utils } from "../hooks/useWeb3Utils"
import { MAX_APPROVE_AMOUNT } from "./constants";
const useBuyAndMintUtils = () => {

  const { Moralis } = useMoralis();

  const { executeWeb3CallAndConfirm } = useWeb3Utils()

  const [isMinting, setIsMinting] = useState(false)

  const [isApproving, setIsApproving] = useState(false)

  const marketplaceContext = useContext(MarketplaceContext)
  // console.log("loading useBuyAndMintUtils")

  const { enableWeb3, isWeb3Enabled, isWeb3EnableLoading, web3EnableError } = useMoralis()

  const { switchNetwork, chainId, account } = useChain();

  //{ data, error, fetch, isFetching, isLoading }
  const { fetch } = useWeb3ExecuteFunction();

  useEffect(() => {
    const checkAndExecuteWeb3 = async () => {

      if (!Moralis.Web3.connector) return;

      if (!isWeb3Enabled && !isWeb3EnableLoading) {
        await enableWeb3()
      }
      if (web3EnableError) {
        // alert(web3EnableError)
      }

      const marketplaceBlockchainId = marketplaceContext.marketplaceDetails.blockchainId
      if (chainId !== marketplaceBlockchainId && chainId) {
        console.log("wallet blockchcain " + chainId
          + " does not match marketplace blockchain " +
          marketplaceBlockchainId + "isWeb3EnableLoading " + isWeb3EnableLoading)
        switchNetwork(marketplaceBlockchainId)
      }
    }

    window.addEventListener("focus", checkAndExecuteWeb3);
    // window.addEventListener("blur", onBlur);
    // Calls onFocus when the window first loads
    checkAndExecuteWeb3();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", checkAndExecuteWeb3);
      // window.removeEventListener("blur", onBlur);
    };
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
    //[chainId,Moralis.Web3.connector, enableWeb3, isWeb3EnableLoading, isWeb3Enabled, marketplaceContext.marketplaceDetails.blockchainId, switchNetwork, web3EnableError]
  );

  async function getERC20MintAddressForNFT(
    NFTAddress) {

    console.log("NFTAddress " + NFTAddress)

    const ops = {
      contractAddress: NFTAddress,
      functionName: "getERC20MintToken",
      abi: ERC721Abi.abi,
      params: {
      }
    };


    let ERC20TokenAdress;
    await fetch({
      params: ops,
      onSuccess: (mintToken) => {
        console.log("getERC20MintToken sucess " + mintToken);
        ERC20TokenAdress = mintToken
      },
      onError: (error) => {
        console.error("getERC20MintToken  error" + error
          + " is web3 enabled" + isWeb3Enabled
        )
      },
    });


    console.log("ERC20TokenAdress " + ERC20TokenAdress)




    return ERC20TokenAdress;
  }



  async function buyAndMint(
    NFTAddress, mintPrice) {




    setIsMinting(true)



    const ERC20TokenAdress = await getERC20MintAddressForNFT(NFTAddress)

    console.log("erc20ToSpend " + ERC20TokenAdress)

    console.log("ERC20TokenAdress " + ERC20TokenAdress)



    const executeWhenAllowanceIsReady = async function () {


      console.log("start minting")
      console.log("metamaskkk shuold show up")

      const options = {
        contractAddress: NFTAddress,
        functionName: "mint",
        abi: ERC721Abi.abi,
        params: {
          to: account,
        },
      };

      if(ERC20TokenAdress === "0x0000000000000000000000000000000000000000"){
        console.log("mintPricemintPrice"+ mintPrice)
        const wei = String(mintPrice) + 10 **18
        console.log("wei mint price orgi " + wei)
        options.msgValue = mintPrice
        
      }

      await executeWeb3CallAndConfirm(options)
      console.log("all minted up?")
      setIsMinting(false)



    }

    const canBuyNFT = await hasEnoughAllowance(ERC20TokenAdress, NFTAddress, mintPrice);
    if (canBuyNFT) {
      console.log(" no need to ask for allowance we are good")
      executeWhenAllowanceIsReady()
    } else {
      console.log("setIsApproving(true)")
      setIsApproving(true)


      const options = {
        contractAddress: ERC20TokenAdress,
        functionName: "approve",
        abi: ERC20Schema.abi,
        params: {
          _value: String(MAX_APPROVE_AMOUNT),
          //  _value: String(mintPrice),
          _spender: NFTAddress
        },
      };

      await executeWeb3CallAndConfirm(options)
      setIsApproving(false)
      await executeWhenAllowanceIsReady()
    }

  }



  // spender can be NFT address, which consumes tokens to spend to mint
  async function hasEnoughAllowance(contractTokenAddressToSpend,
     spender, price) {

      if(contractTokenAddressToSpend === "0x0000000000000000000000000000000000000000"){
        return true;
      }

    let gotAllowance = 0;

    const ops = {
      contractAddress: contractTokenAddressToSpend,
      functionName: "allowance",
      abi: ERC20Schema.abi,
      params: {
        _owner: account,
        _spender: spender
      }
    };

    await fetch({
      params: ops,
      onSuccess: (allowance) => {
        console.log("allowance success is enough " + allowance);
        gotAllowance = Number(allowance)
      },
      onError: (error) => {
        console.error("allowance error" + error)
      },
    });


    return gotAllowance >= price;
  }

  return {
    getERC20MintAddressForNFT, buyAndMint,
    hasEnoughAllowance,
    isMinting,
    isApproving
  }
}


export { useBuyAndMintUtils }
