import { MarketplaceContext } from "./../../helpers/marketplaceContext"
import { useBuyAndMintUtils } from "./../../helpers/buyAndMint"

import { useState, useContext, useEffect } from "react";
import { useERC20Balances } from "react-moralis";


function ERC20TokensBalance({ inputValue }) {


    const { getERC20MintAddressForNFT } = useBuyAndMintUtils()
    const marketplaceContext = useContext(MarketplaceContext)

    const { fetchERC20Balances, data,
        isLoading: fetchERC20BalancesLoading } = useERC20Balances();
    const [tokenBalances, setTokenBalances] = useState([])



    useEffect(() => {

        const fetchData = async () => {

            console.log("data fetchData " + JSON.stringify(data))


            console.log(" fetchData loading")
            const NFTs = marketplaceContext.marketplaceDetails.marketplaceNFTCollections.data.map(x => x.NFTCollection)

            console.log("fetchERC20BalancesLoading " + fetchERC20BalancesLoading)

            if (!data) {
                await fetchERC20Balances();
            }

            console.log("erc20balances " + JSON.stringify(data))
            const tokensToDisplay = new Set(tokenBalances);

            const promises = NFTs.map(async (nft) => {
                console.log("get token " + JSON.stringify(nft));
                console.log("getERC20MintAddressForNFT")
                const mintToken = await getERC20MintAddressForNFT(nft.contractAddress);

                (data || []).forEach(token => {
                    console.log("erc20balances token.token_address "
                        + token.token_address + " vs mintToken " + mintToken)

                    console.log("mintToken ==    token.token_address " + String(mintToken === token.token_address))
                    if (mintToken.toLowerCase() === token.token_address.toLowerCase()) {
                        console.log("add1 token_address " + token.token_address)
                        setTokenBalances(oldTokenBalance => [
                            ...oldTokenBalance,
                            token
                        ])

                    }
                })
                console.log("minttoken await" + mintToken)

                console.log("tokensToDisplay " + JSON.stringify(tokensToDisplay))

            });

            await Promise.all(promises)


            console.log("account nfts " + JSON.stringify(NFTs))
        }
        fetchData();


        // eslint-disable-next-line     
    }, [data])


const arrayUniqueByKey = (key,array) => [...new Map(array.map(item =>
    [item[key], item])).values()];

    const renderTokenBalances = () => {
        console.log("tokenBalances render " + JSON.stringify(tokenBalances))
        const tokens = arrayUniqueByKey("token_address", tokenBalances)
        const rows = tokens.map(tokenBalance => {
            const balance = Number(tokenBalance.balance) / 10 ** (tokenBalance.decimals)
            return (
                <tr>
                    <td>
                        {tokenBalance.name}
                    </td>
                    <td>
                        {balance}
                    </td>

                </tr>


            )
        }

        )

        return (
            <table>
                <tbody>

                    {rows}

                </tbody>
            </table>
        )
    }




    return (
        <div>
            {renderTokenBalances()}
        </div>
    )

}

export { ERC20TokensBalance }
