import { useBuyAndMintUtils } from "../helpers/buyAndMint";
import { useMoralis } from "react-moralis";
import { useGetMintPriceForNFT } from "hooks/useGetMintPriceForNFT"
import { useEffect, useState } from "react";

function MintButton({ inputValue }) {

    console.log("MintButton22 render " + JSON.stringify(inputValue))

    const buttonClasses = `
    bg-blue-500 hover:bg-blue-700 text-white font-bold px-16 rounded
    w-full md:w-80 h-10
    `
    const disabledButtonClasses = `
    bg-gray-500 text-white font-bold px-16 rounded
    w-full md:w-80 h-10
    `

    const { isApproving, isMinting, buyAndMint } = useBuyAndMintUtils()
    const { mintPrice } = useGetMintPriceForNFT(inputValue.contractAddress)

    const [mintButtonText, setMintButtonText] = useState("Loading price")

    const { authenticate, isAuthenticated, enableWeb3,
        isWeb3Enabled, isWeb3EnableLoading
    } = useMoralis();


    useEffect(() => {
        console.log("setting mint price text isMinting "+ isMinting)
        if(isMinting){
            setMintButtonText("Minting NFT")
        }
        if(mintPrice){
            setMintButtonText(` ${mintPrice / (10 ** inputValue.mintDecimals)} ${inputValue.mintTokenName}`)
        }

    },[isMinting, setMintButtonText, inputValue.mintDecimals, inputValue.mintTokenName, mintPrice])

    // const renderMintPrice = () => {
    //     console.log("mintPrice22 " + inputValue.mintDecimals)

    //     if (!mintPrice || !inputValue?.mintDecimals) {
    //         return "Loading price"
    //     }
    //     if (isMinting) {
    //         return "Minting NFT"
    //     }


    //     return (
            
    //     )
    // }


    if (!isAuthenticated || !isWeb3Enabled || isWeb3EnableLoading) {
        console.log("!isAuthenticated || !isWeb3Enabled || isWeb3EnableLoading")
        return <button
        className={buttonClasses}
        onClick={async () => {
            await authenticate({signingMessage: "Authentication" })
            enableWeb3()
        }}
        >
            connect your wallet to mint item
        </button>
    }

    return (
<div>
<button
        className={isMinting ? disabledButtonClasses :
            buttonClasses}
            disabled={isMinting}
            onClick={() => {
                buyAndMint(
                    inputValue.contractAddress,
                    mintPrice
                )

            }}>
            {
                isApproving ? `Approving ${inputValue.mintTokenName}` :
                isMinting ? "Minting" : "BUY"
            }
        </button>

<div className="text-lg text-gray-500 font-medium my-5">
            PRICE:  {mintButtonText}
            

          </div>

</div>
    
    )

}

export { MintButton }
