import React, { useState, useEffect } from 'react';
// import { useMoralisDapp } from "providers/MoralisDappProvider/MoralisDappProvider";
import { useMarketplaceData } from "../hooks/useMarketplaceData"
import {  Spin } from "antd";
import { useChain, useMoralis } from 'react-moralis';

export const MarketplaceContext = React.createContext();

const ROOT_API = process.env.REACT_APP_ROOT_BLOCKONE_API || "https://api.blockonelabs.com/api"
export const MarketplaceProvider = ({ children }) => {

  const [loading, setLoading] = useState(true)
  const [marketplaceDetails, setMarketplaceDetails] = useState([]);
  const [reference, setReference] = useState()
  // const [callback, setCallback] = useState()
  const { account } = useChain();
  const { user, logout } = useMoralis();


  const [stylePath, setStylePath] = useState(
    ""
  );

  const {marketplaceData} = useMarketplaceData()

  useEffect(() => {
    if(stylePath){

      var head = document.head;
      var link = document.createElement("link");
  
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = stylePath;
  
  
      head.appendChild(link);
  
      // return () => { head.removeChild(link); }
    }


  }, [stylePath]);



  //   console.log(" loading " + loading)
  useEffect(() => {
    // console.log("retrieving mktplace data")

  const retrieveMarketplaceData = async () => {


    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if(params && Object.keys(params).length > 0){
      localStorage.setItem("queryParams", JSON.stringify(params));
    }
    const webhookParams = localStorage.getItem("queryParams") || "{}";


    const referenceParam = params
    if(referenceParam){
      setReference(referenceParam)
    }
    if(user && account && user.ethAddress && account !== user.ethAddress) {
      console.warn(`logged in with different metamask address logging out\n
      account: ${account} \n
      user.ethAddress: ${user.ethAddress}
      `)
      logout()
      return;
    }
  
    const jsonUser = JSON.parse(JSON.stringify(user || {}))
    const ethAddress = jsonUser.ethAddress
    const marketplaceId = marketplaceData._id


    // console.log(`
    // user: ${JSON.stringify(user)} \n
    // account: ${account} \n
    // (user || {}).ethAddress: ${ethAddress}
    // `)
    if (account && ethAddress){
     
      const url = `${ROOT_API}/webhook/marketplace`
      fetch(url,
{
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "POST",
    body: JSON.stringify({
      queryParams: webhookParams, 
      marketplaceId,
      user: user})
})
.then(function(res){ console.log(` callback ${JSON.stringify(res)}`) })
.catch(function(res){ console.log(`calback ${JSON.stringify(res)}`) })


    }    

    // const marketplaceId = window.location.pathname.split('/')[1];

    // console.log("marketplaceId " + marketplaceId)

    


    const marketPlace = (marketplaceData || { marketplaceNFTCollections: [] })
    const res = marketPlace.marketplaceNFTCollections
    const customStyleSheetURL = marketPlace.customStyleSheetURL
    setStylePath(customStyleSheetURL)

    // const webhookURL = marketPlace.webhookURL
    // console.log("webhookURL2 " +webhookURL)
    // if(webhookURL){
    //   setCallback(webhookURL)
    // }

    // console.log("res.data " + JSON.stringify(res.data))

    const details = res.data.map(x => x.NFTCollection.contractAddress)
    // console.log("details " + JSON.stringify(details))

    // console.log("asd " + JSON.stringify(asd))
    setMarketplaceDetails({
      ...marketPlace,
      NFTs: details
    })
    setLoading(false)

  }

  if(marketplaceData) {
    retrieveMarketplaceData();
  }
    
  }, [account, marketplaceData, user, logout])


  if (loading) {
    return (
     
      <div className="flex justify-center mt-20">
    <Spin size="large"  spinning={true}/>

    </div>

    )
  }
  // console.log("stylePath " + stylePath)
  return (
    <div>

    <MarketplaceContext.Provider
      value={{ marketplaceDetails, reference }}>
      {children}
    </MarketplaceContext.Provider>
    </div>

  );
};