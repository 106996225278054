import { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  Navigate
} from "react-router-dom";
import Account from "components/Account";
// import Chains from "components/Chains";
import NFTBalance from "components/NFTBalance";
import NFTTokenIds from "./components/NFTTokenIds";
// import SearchCollections from "components/SearchCollections";
import "antd/dist/antd.css";
import "./style.css";
import NFTMintPlace from "./components/NFTMintPlace"
import NFTDetails from "./components/NFTDetails"
import logo from './assets/defaultLogo.png';
import { MarketplaceProvider } from "helpers/marketplaceContext";
import { getMarketplaceIdFromLocation } from "helpers/URLUtils"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const App = ({ isServerInfo }) => {

  const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } =
    useMoralis();

  const marketplaceId = getMarketplaceIdFromLocation()

  const [inputValue, setInputValue] = useState();

  const [isMobileMenuHidden, setIsMobileMenuHidde] = useState(true);

  const [clickedTopMenu, setClickedTopMenu] = useState("NFTMintPlace")

  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) enableWeb3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  return (
    <MarketplaceProvider>
     
     
        <Router>

        <nav className="bg-white border-gray-200 px-2">
  <div className="md:flex w-full">
<div className="flex justify-between">
<Logo />
    <div 
    onClick={() => setIsMobileMenuHidde(old => !old)}
    className="md:hidden justify-end	">
      <FontAwesomeIcon icon={faBars} />

    </div>
</div>

    
<div className={`${isMobileMenuHidden ? "hidden" :"" } w-full md:flex `} id="menu">
          <div
            className="
              pt-4
              text-gray-700
              md:flex
              md:pt-0
              md:w-11/12
              md:justify-center	
              md:gap-10
              md: mt-3
              text-lg	
              "
          >
            <div>
             
            <div key="nft"
                        className={clickedTopMenu === "nftBalance" ? "border-b-2" :""}

            onClick={() => 
              {
               setClickedTopMenu("nftBalance")
               setInputValue("explore")
              }}
            >
                <NavLink to={`${marketplaceId}/nftBalance`}>
                  Your Collection</NavLink>
              </div>
            </div>
            <div>
            
            <div 
            
            className={clickedTopMenu === "NFTMintPlace" ? "border-b-2" :""}
            
             key="NFTMintPlace" onClick={() => 
             {
              setClickedTopMenu("NFTMintPlace")
              setInputValue("explore")
             }
             }>
                <NavLink to={`${marketplaceId}/NFTMintPlace`}>Mint Assets</NavLink>
              </div>
            </div>
      
    
          </div>

          <div className="float-right">
          
           
          <Account />
          </div>
        </div>
  </div>
</nav>


        
  


          <div>


            <Routes >

                <Route path={`${marketplaceId}/nftBalance`}

                  element={<NFTBalance />} >
                </Route>
                <Route 
                path={`${marketplaceId}/NFTMarketPlace`}

                  element={
                    <NFTTokenIds inputValue={inputValue} setInputValue={setInputValue} />
                  }
                >
                </Route>

                <Route 
                        path={`${marketplaceId}/NFTMintPlace`}
                         element={
                  <NFTMintPlace
                    inputValue={inputValue} setInputValue={setInputValue} />
                }>

                </Route>
                <Route
                path={`${marketplaceId}/NFTDetails/:nftCollectionId`}
                 element={
                  <NFTDetails inputValue={inputValue} setInputValue={setInputValue} />

                }>

                </Route>
                <Route path={`${marketplaceId}`} element={<Navigate replace to={`/${marketplaceId}/NFTMintPlace`} />} />



            </Routes>
            {/* <Redirect to="/NFTMarketPlace" /> */}

          </div>
        </Router>
    </MarketplaceProvider>
  );
};

export const Logo = () => (
  <div style={{ display: "flex" }}>

    <img alt="logo" className="marketplaceSmallLogo" width="70"
      height="10" src={logo} />

  </div>
);

export default App;
